import { convertTimestampToDate } from "../../../utils/CalculateFunctions/CalculateFunctions";
import DetailsButton from "./DetailsButton";

export const columns = [
    {
        name: 'ID',
        selector: row => row.id,
        sortable: true,
        width: '100px',
    },
    {
        name: 'Nombre',
        selector: row => `${row.Name} ${row.Last_name}`,
        sortable: true,
    },
    {
        name: 'Correo',
        selector: row => row.Email,
        sortable: true,
        center: true,
    },
    {
        name: 'Celular',
        selector: row => row.Phone_number,
        sortable: true,
        center: true,
    },
    {
        name: 'Registro',
        selector: row => convertTimestampToDate(row.date_registration_flotilla),
        sortable: true,
        center: true,
    },
    {
        name: 'Documentos',
        selector: row => row.doc_verification ? 'Sí' : 'No',
        sortable: true,
        center: true,
        cell: row => (
            <span
                className={`px-4 py-1 rounded-xl text-white ${row.doc_verification ? 'bg-greenE' : 'bg-redD'
                    }`}
            >
                {row.doc_verification ? 'Sí' : 'No'}
            </span>
        ),
    },
    {
        name: 'Flotillas',
        selector: row => row.flotillas.length,
        sortable: true,
        center: true,
        width: '150px',
    },
    {
        name: 'Vehículos',
        selector: row => row.vehiculos.length,
        sortable: true,
        center: true,
    },
    {
        name: 'Detalles',
        selector: row => row.vehiculos.length,
        center: true,
        cell: row => <DetailsButton row={row} />,
    },
];
