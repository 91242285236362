import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';

const Conductores = ({ activos, pendientes, loading, error }) => {
    const chartRef = useRef(null);

    useEffect(() => {
        if (chartRef.current && (activos > 0 || pendientes > 0)) { // Verifica que el elemento esté presente
            const chartInstance = echarts.init(chartRef.current);

            const option = {
                tooltip: {
                    trigger: 'item',
                },
                legend: {
                    top: '5%',
                    left: 'center',
                },
                series: [
                    {
                        name: 'Conductores',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: '#fff',
                            borderWidth: 2,
                        },
                        label: {
                            show: false,
                            position: 'center',
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 20,
                                fontWeight: 'bold',
                            },
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [
                            {
                                value: activos,
                                name: 'Activos',
                                itemStyle: { color: '#4CAF50' },
                            },
                            {
                                value: pendientes,
                                name: 'Pendientes',
                                itemStyle: { color: '#FFC107' },
                            },
                        ],
                    },
                ],
            };

            chartInstance.setOption(option);

            return () => {
                chartInstance.dispose();
            };
        }
    }, [activos, pendientes]);

    return (
        <div className="flex items-center justify-center h-full w-full">
            <div className="min-h-[300px] w-[300px] flex items-center justify-center">
                {!loading && !error && (activos === 0 && pendientes === 0) ? (
                    <h2 className="text-center text-xl font-bold">
                        No hay conductores registrados en el rango de fechas seleccionado
                    </h2>
                ) : (
                    <div>
                        <h2 className="xs:text-xl font-bold text-center">Conductores</h2>
                        <div ref={chartRef} style={{ width: '300px', height: '300px' }}></div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Conductores;
