import React, { useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ModalFlotillero from './ModalFlotillero'; 

const DetailsButton = ({ row }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    return (
        <>
            <button
                className='bg-BlueD text-white px-3 py-1 rounded-full hover:bg-orange-500 hover:scale-110'
                onClick={openModal}
            >
                <InfoOutlinedIcon sx={{ fontSize: '1.1rem', margin: '.25rem' }} />
            </button>

            <ModalFlotillero
                row={row}
                isOpen={modalIsOpen}
                onClose={closeModal}
            />
        </>
    );
};

export default DetailsButton;
