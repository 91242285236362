import * as echarts from 'echarts';
import React, { useEffect, useRef } from 'react';

const Vehiculos = ({ vehicleData }) => {
    const chartRef = useRef(null);

    const localidadesMap = {};
    if (vehicleData && vehicleData.drivers) {
        vehicleData.drivers.forEach(vehicle => {
            const localidad = vehicle.address_locality || 'Desconocido';
            const tipoVehiculo = vehicle.vehicle_id_type?.name || 'Desconocido';

            if (!localidadesMap[localidad]) {
                localidadesMap[localidad] = {
                    'Security and Comfort': 0,
                    'Nikucar': 0,
                    'Niku Lady': 0,
                    'Nikucar Taxi': 0,
                    'Security': 0,
                };
            }
            if (localidadesMap[localidad][tipoVehiculo] !== undefined) {
                localidadesMap[localidad][tipoVehiculo] += 1;
            }
        });
    }

    const localidades = Object.keys(localidadesMap);
    const totalVehiculosPorLocalidad = localidades.map(localidad => {
        const total = Object.values(localidadesMap[localidad]).reduce((sum, count) => sum + count, 0);
        return { localidad, total };
    });

    totalVehiculosPorLocalidad.sort((a, b) => b.total - a.total);

    const localidadesOrdenadas = totalVehiculosPorLocalidad.map(item => item.localidad);
    const securityAndComfortData = localidadesOrdenadas.map(localidad => localidadesMap[localidad]['Security and Comfort']);
    const nikucarData = localidadesOrdenadas.map(localidad => localidadesMap[localidad]['Nikucar']);
    const nikuLadyData = localidadesOrdenadas.map(localidad => localidadesMap[localidad]['Niku Lady']);
    const nikucarTaxiData = localidadesOrdenadas.map(localidad => localidadesMap[localidad]['Nikucar Taxi']);
    const securityData = localidadesOrdenadas.map(localidad => localidadesMap[localidad]['Security']);

    useEffect(() => {
        if (chartRef.current) {
            const chartInstance = echarts.init(chartRef.current);
            const options = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                    },
                },
                legend: {
                    data: ['Security and Comfort', 'Nikucar', 'Niku Lady', 'Nikucar Taxi', 'Security'],
                },
                xAxis: {
                    type: 'category',
                    data: localidadesOrdenadas,
                    axisLabel: {
                        show: localidadesOrdenadas.length <= 15,
                        rotate: 45,
                        interval: 0,
                    },
                },
                yAxis: {
                    type: 'value',
                },
                series: [
                    {
                        name: 'Security and Comfort',
                        type: 'bar',
                        stack: 'total',
                        data: securityAndComfortData,
                        itemStyle: {
                            color: '#4CAF50',
                        },
                    },
                    {
                        name: 'Nikucar',
                        type: 'bar',
                        stack: 'total',
                        data: nikucarData,
                        itemStyle: {
                            color: '#2196F3',
                        },
                    },
                    {
                        name: 'Niku Lady',
                        type: 'bar',
                        stack: 'total',
                        data: nikuLadyData,
                        itemStyle: {
                            color: '#FFC107',
                        },
                    },
                    {
                        name: 'Nikucar Taxi',
                        type: 'bar',
                        stack: 'total',
                        data: nikucarTaxiData,
                        itemStyle: {
                            color: '#FF5722',
                        },
                    },
                    {
                        name: 'Security',
                        type: 'bar',
                        stack: 'total',
                        data: securityData,
                        itemStyle: {
                            color: '#9C27B0',
                        },
                    },
                ],
            };

            chartInstance.setOption(options);

            return () => {
                chartInstance.dispose();
            };
        }
    }, [localidadesOrdenadas, securityAndComfortData, nikucarData, nikuLadyData, nikucarTaxiData, securityData]);

    return (
        <div className="flex items-center justify-center h-full">
            <div className="min-h-[400px] w-full flex items-center justify-center">
                {localidadesOrdenadas.length > 0 ? (
                    <div>
                        <h2 className="text-xl font-bold text-center">
                            Vehículos por localidad y tipo
                        </h2>
                        <div ref={chartRef} style={{ width: '1000px', height: '400px' }}></div>
                    </div>
                ) : (
                    <h2 className="text-xl font-bold text-center">
                        No hay vehículos registrados en el rango de fechas seleccionado
                    </h2>
                )}
            </div>
        </div>
    );
};

export default React.memo(Vehiculos);
