import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { GET_POLIGONOS, GET_TRAVEL_HISTORY, GET_TYPE_VEHICLE, GET_USERS, STATUS_DRIVER, STATUS_DRIVER_ZONE } from '../../schemas/querys';
import { convertDateToTimestamp, getCurrentDay, getFirstDayOfMonth } from '../../utils/CalculateFunctions/CalculateFunctions';
import DateRangeSelectores from '../DateRangeSelector/DateRangeSelector';
import SelectZones from '../selectZone/SelectZones';
import Conductores from './Home/Conductores';
import Historial from './Home/Historial';
import Pasajeros from './Home/Pasajeros';
import Vehiculos from './Home/Vehiculos';
import DropdownFechas from '../DateRangeSelector/DropdownFechas ';
import Loading from '../../assets/img/Loading.json';
import Lottie from 'lottie-react';

const Home = () => {
	const [city, setCity] = useState("Todas las ciudades");
	const [nameCity, setNameCity] = useState("Todas las zonas");
	const [startDate, setStartDate] = useState(getFirstDayOfMonth());
	const [endDate, setEndDate] = useState(getCurrentDay());
	const startDateTimestamp = convertDateToTimestamp(startDate);
	const endDateTimestamp = convertDateToTimestamp(endDate);
	const [activos, setActivos] = useState(0);
	const [pendientes, setPendientes] = useState(0);
	const startDateString = startDateTimestamp.toString();
	const endDateString = endDateTimestamp.toString();

	const { data: Poligonos } = useQuery(GET_POLIGONOS, {
		fetchPolicy: "cache-and-network",
	});

	const queryDrivers = nameCity === "Todas las zonas" ? STATUS_DRIVER : STATUS_DRIVER_ZONE;
	const variablesDrivers = nameCity === "Todas las zonas"
		? { startDate: startDateTimestamp, endDate: endDateTimestamp }
		: { startDate: startDateTimestamp, endDate: endDateTimestamp, address_locality: nameCity };

	const { data, loading, error } = useQuery(queryDrivers, {
		variables: variablesDrivers,
		fetchPolicy: "network-only",
		onError: (error) => {
			console.error("Error al obtener datos:", error);
		},
	});

	const variablesHistory = {
		startDate: startDateTimestamp,
		endDate: endDateTimestamp,
	};

	const { data: dataHistory, loading: loadingHistory, error: errorHistory } = useQuery(GET_TRAVEL_HISTORY, {
		variables: variablesHistory,
		fetchPolicy: "network-only",
	});

	const { data: usersData, loading: usersLoading, error: usersError } = useQuery(GET_USERS, {
		variables: { startDate: startDateString, endDate: endDateString },
		fetchPolicy: "network-only",
		onError: (error) => {
			console.error("Error al obtener usuarios:", error);
		},
	});

	const { data: vehicleData, loading: vehicleLoading, error: vehicleError } = useQuery(GET_TYPE_VEHICLE, {
		variables: variablesDrivers,
		fetchPolicy: "network-only",
		onError: (error) => {
			console.error("Error al obtener tipo de vehiculos", error)
		}
	})

	useEffect(() => {
		if (data && data.drivers) {
			const activosCount = data.drivers.filter(driver => driver.status === "Activo").length;
			const pendientesCount = data.drivers.filter(driver => driver.status === "Pendiente").length;
			setActivos(activosCount);
			setPendientes(pendientesCount);
		} else {
			console.warn("No se encontraron conductores para la ciudad seleccionada.");
		}
	}, [data]);

	const isLoading = loading || loadingHistory || usersLoading || vehicleLoading;

	if (isLoading) {
		return (
			<div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-75 z-50">
				<Lottie animationData={Loading} loop={true} style={{ width: 1500, height: 1500 }} />
			</div>
		);
	}

	return (
		<div className='flex flex-col h-full'>
			<div className='flex justify-between mx-8 mt-4 mb-4'>
				<div className='flex items-center'>
					<DateRangeSelectores
						startDate={startDate}
						endDate={endDate}
						setStartDate={setStartDate}
						setEndDate={setEndDate}
					/>
					<DropdownFechas setStartDate={setStartDate} setEndDate={setEndDate} />
				</div>
				<div >
					<h1 className='text-sm text-center mb-1'>
						Filtra conductores por zona
					</h1>
					<SelectZones
						poligons={Poligonos}
						setCity={setCity}
						setNameCity={setNameCity}
						city={city}
					/>
				</div>
			</div>
			<div className='grid lg:grid-cols-3 lg:grid-rows-2 gap-4 mx-6 flex-col md:grid-cols-2 md:grid-rows-3 sm:grid-rows-1 my-auto mb-6'>
				<div className='bg-white p-4 overflow-x-auto lg:col-span-2'>
					<Historial dataHistory={dataHistory} loadingHistory={loadingHistory} error={errorHistory}
						startDate={startDateTimestamp} endDate={endDateTimestamp} />
				</div>
				<div className='bg-white p-4 overflow-x-auto'>
					<Conductores nameCity={nameCity} activos={activos} pendientes={pendientes} loading={loading} error={error} />
				</div>
				<div className='bg-white p-4 overflow-x-auto'>
					<Pasajeros usersData={usersData} usersLoading={usersLoading} />
				</div>
				<div className='bg-white p-4 overflow-x-auto lg:col-span-2'>
					<Vehiculos vehicleData={vehicleData} />
				</div>
			</div>
		</div>
	);
};

export default Home;
