import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import dayjs from 'dayjs';

const Pasajeros = ({ usersData }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (usersData && usersData.user && usersData.user.length > 0) {
      const formattedData = usersData.user.map(user => ({
        date: dayjs(Number(user.register_date)).format('YYYY-MM-DD'),
      }));

      const registrationCountByDate = formattedData.reduce((acc, { date }) => {
        acc[date] = (acc[date] || 0) + 1;
        return acc;
      }, {});

      const dates = Object.keys(registrationCountByDate).sort();
      const counts = dates.map(date => registrationCountByDate[date]);

      const chart = echarts.init(chartRef.current);

      const options = {
        xAxis: {
          type: 'category',
          data: dates,
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: counts,
            type: 'line',
            smooth: true,
          },
        ],
        tooltip: {
          trigger: 'axis',
        },
      };

      chart.setOption(options);

      return () => {
        chart.dispose();
      };
    }
  }, [usersData]);

  if (!usersData || !usersData.user || usersData.user.length === 0) {
    return (
      <div className="flex items-center justify-center h-full text-center font-bold">
        <h2>No hay usuarios registrados en el rango de fechas seleccionado</h2>
      </div>
    );
  }

  return (
    <div>
      <h2 className="text-xl font-bold text-center">Registro de usuarios por día</h2>
      <div ref={chartRef} style={{ width: '100%', height: '400px' }}></div>
    </div>
  );
};

export default Pasajeros;
