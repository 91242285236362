import { gql } from "@apollo/client";

export const UPDATE_VALIDATION_DOCS_FLOTILLARS = gql`
  mutation MyMutation($validation: String!, $_eq: String!) {
    update_drivers_photos_docs(
      where: { photo_docs_id: { _eq: $_eq } }
      _set: { validation: $validation }
    ) {
      affected_rows
      returning {
        validation
      }
    }
  }
`;

export const UPDATE_DOC_VERIFICATION = gql`
  mutation MyMutation($doc_verification: Boolean!, $_eq: String!) {
    update_Flotilleros(
      where: { id: { _eq: $_eq } }
      _set: { doc_verification: $doc_verification }
    ) {
      affected_rows
      returning {
        doc_verification
      }
    }
  }
`;
