import React from 'react';
import { useQuery } from '@apollo/client';
import DataTable from 'react-data-table-component';
import { GET_FLOTILLARS } from '../../../schemas/querys';
import { columns } from './columnTablaFlotilleros';

export default function TablaFlotilleros() {
    const { loading, error, data } = useQuery(GET_FLOTILLARS, {
        variables: {
            limit: 10,
            startDate: 1717194194880,
            endDate: 1717280594890
        },
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <div>
            <DataTable
                columns={columns}
                data={data.Flotilleros}
                pagination
                paginationPerPage={10}
                highlightOnHover
                responsive={true}
                customStyles={{
                    rows: {
                        style: {
                            borderTop: '1px solid #CBD5E0',
                        },
                    },
                    selected: {
                        style: {
                            color: '#008000',
                        },
                    },
                    headCells: {
                        style: {
                            fontSize: '1.25rem',
                            textAlign: 'center',
                        },
                    },
                    pagination: {
                        style: {
                            fontSize: '1rem',
                            color: 'black',
                        },
                    },
                    cells: {
                        style: {
                            marginTop: '1rem',
                            width: '10vw',
                            marginBottom: '1rem',
                            textAlign: 'center',
                            fontSize: '1rem',
                        },
                    },
                    table: {
                        style: {
                            height: '77vh',
                            header: 'sticky',
                            overflowX: 'hidden',
                        },
                    },
                }}
            />
        </div>
    );
}
