import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import formatDate from '../../../formatDate';
import DateRangeSelectores from '../../DateRangeSelector/DateRangeSelector';

const TablaHistorial = ({ rowData }) => {
    const [trips, setTrips] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [filteredTrips, setFilteredTrips] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState('Todos');

    useEffect(() => {
        const fetchTripsData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_DRIVERs_ID}/${rowData.driver_id}`,
                    {
                        headers: {
                            "x-hasura-admin-secret": process.env.REACT_APP_API_KEY,
                        },
                    }
                );
                setTrips(response.data.trips);
                setFilteredTrips(response.data.trips);
            } catch (error) {
                console.error("Error fetching trips data:", error);
            }
        };
        fetchTripsData();
    }, [rowData]);

    const columns = [
        {
            name: 'ID Viaje',
            selector: row => row.viajeId,
            width: '120px',
            sortable: true,
        },
        {
            name: 'Estatus',
            selector: row => {
                const statusMapping = {
                    'Terminado': 'Completado',
                    'Cancelado': 'Cancelado',
                };
                return statusMapping[row.status] || row.status;
            },
            width: '135px',
            sortable: true,
        },
        {
            name: 'Fecha de solicitud',
            selector: row => formatDate(row.fechaSolicitud),
            width: '170px',
            sortable: true,
        },
        {
            name: 'Metodo de pago',
            selector: row => (row.payment_method),
            sortable: true,
            width: '180px',
        },
        {
            name: 'Tarifa de Viaje',
            selector: row => ("$" + row.total),
            sortable: true,
            width: '160px',
            right: 'true'
        },
        {
            name: 'Cuota de solicitud',
            selector: row => ("$" + row.cuota_de_solicitud),
            sortable: true,
            width: '190px',
            right: 'true'
        },
        {
            name: 'IVA 8%',
            selector: row => ("$" + row.plataforma_iva),
            sortable: true,
            width: '110px',
            right: 'true'
        },
        {
            name: 'ISR 2.1%',
            selector: row => ("$" + row.impuestos_isr),
            sortable: true,
            width: '115px',
            right: 'true'
        },
        {
            name: 'Uso de plataforma',
            selector: row => {
                const resultado = row.uso_plataforma * 0.15;
                return "$" + resultado.toFixed(2);
            },
            sortable: true,
            right: 'true'
        },
        {
            name: 'Comisión 15%',
            selector: row => {
                const resultado = row.subtotal_tarifa_dinamica * 0.15;
                return "$" + resultado.toFixed(2);
            },
            width: '165px',
            sortable: true,
            right: 'true'
        },
        {
            name: 'Total a recibir',
            selector: row => ("$" + row.pago_total),
            sortable: true,
            width: '190px',
            right: 'true'
        },
    ];

    const applyFilters = () => {
        const filteredTrips = trips.filter((trip) => {
            const tripDate = new Date(trip.fechaSolicitud).getTime();
            const dateFilter = (!startDate || !endDate ||
                (tripDate >= new Date(startDate).getTime() && tripDate <= new Date(endDate).getTime()));
            const statusFilter = (selectedStatus === 'Todos' || trip.status === selectedStatus);
            return dateFilter && statusFilter;
        });

        setFilteredTrips(filteredTrips);
    };

    useEffect(() => {
        applyFilters();
    }, [selectedStatus, startDate, endDate]);

    const resetFilter = () => {
        setStartDate(null);
        setEndDate(null);
        setFilteredTrips(trips);
    };

    const paginationComponentOptions = {
        rowsPerPageText: "Filas por página",
        rangeSeparatorText: "de",
        selectAllRowsItem: true,
        selectAllRowsItemText: "Todos",
    };

    return (
        <div className=' mx-auto'>
            <div className="flex justify-center">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                        <div className="ml-2 flex items-center">
                            <label htmlFor="statusSelect" className="text-sm font-medium text-gray-700">
                                Estatus:
                            </label>
                            <select
                                id="statusSelect"
                                name="status"
                                value={selectedStatus}
                                onChange={(e) => {
                                    setSelectedStatus(e.target.value);
                                    applyFilters();
                                }}
                                className="ml-2 p-2 border border-gray-300 rounded-md focus:outline-none focus:border-orange-600 transition duration-150"
                            >
                                <option value="Todos">Todos</option>
                                <option value="Terminado">Completado</option>
                                <option value="Cancelado">Cancelado</option>
                            </select>
                        </div>
                        <DateRangeSelectores
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                        />
                        <div className="flex items-center">
                            <button className="bg-orange-500 p-1 hover:bg-orange-600 rounded-2xl" onClick={applyFilters}>
                                <FilterAltIcon sx={{ color: 'white' }} />
                            </button>
                            <button className="bg-orange-500 p-1 hover:bg-orange-600 rounded-2xl ml-1" onClick={resetFilter}>
                                <FilterAltOffIcon sx={{ color: 'white' }} />
                            </button>
                        </div>
                    </DemoContainer>
                </LocalizationProvider>
            </div>
            <DataTable
                columns={columns}
                data={filteredTrips}
                noDataComponent={<div className="text-center mt-5 mb-5">No hay datos para mostrar.</div>}
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                highlightOnHover
                paginationComponentOptions={paginationComponentOptions}
                customStyles={{
                    rows: {
                        style: {
                            borderTop: '1px solid #CBD5E0',
                        },
                    },
                    headCells: {
                        style: {
                            fontSize: '1rem',
                        },
                    },
                    cells: {
                        style: {
                            marginTop: '1rem',
                            marginBottom: '1rem',
                            fontSize: '1rem',
                        },
                    },
                    pagination: {
                        style: {
                            fontSize: '1rem',
                            color: 'black',
                        },
                    },
                    table: {
                        style: {
                            height: '45vh',
                            header: 'sticky',
                            overflowX: "hidden"
                        },
                    }
                }}
            />
        </div>
    );
};
export default TablaHistorial;