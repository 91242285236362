import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import React from 'react';

const DateRangeSelectores = ({ startDate, endDate, setStartDate, setEndDate }) => {

    const handleEndDateChange = (newValue) => {
        if (newValue) {
            const updatedEndDate = dayjs(newValue).endOf('day').toDate();
            setEndDate(updatedEndDate);
        } else {
            setEndDate(null);
        }
    };

    return (
        <div className="flex items-center">
            <DatePicker
                label="Fecha de inicio"
                sx={{
                    marginRight: '1rem',
                    width: '12rem',
                    borderColor: '#07f0ad',
                    color: '#07f0ad',
                }}
                value={dayjs(startDate)}
                onChange={(newValue) => setStartDate(newValue ? newValue.toDate() : null)}
                renderInput={(params) => <TextField {...params} />}
            />
            <span className="mr-3 font-bold">-</span>
            <DatePicker
                label="Fecha de fin"
                sx={{
                    width: '12rem',
                }}
                value={dayjs(endDate)}
                onChange={handleEndDateChange}
                renderInput={(params) => <TextField {...params} />}
            />
        </div>
    );
};

export default DateRangeSelectores;
