import React from 'react';
import TablaFlotilleros from './Flotilleros/TablaFlotilleros';

const ViajesFinalizados = () => {
    return (
        <div className='ml-30 mr-30 mt-10'>
            <TablaFlotilleros />
        </div>
    );
}

export default ViajesFinalizados;
