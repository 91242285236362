import { useMutation, useQuery } from '@apollo/client';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TimerIcon from '@mui/icons-material/Timer';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { UPDATE_DOC_VERIFICATION, UPDATE_VALIDATION_DOCS_FLOTILLARS } from '../../../schemas/mutations';
import { GET_DOCUMENTS_FLOTILLARS_BY_ID } from '../../../schemas/querys';
import { convertTimestampToDate } from '../../../utils/CalculateFunctions/CalculateFunctions';

const ModalFlotillero = ({ row, isOpen, onClose }) => {
    const [documentsToShow, setDocumentsToShow] = useState([]);
    const [approvalState, setApprovalState] = useState([]);
    const [buttonText, setButtonText] = useState('Validar documentos'); // Estado para el texto del botón
    const [buttonDisabled, setButtonDisabled] = useState(false); // Estado para habilitar/deshabilitar el botón
    const [updateDocVerification] = useMutation(UPDATE_DOC_VERIFICATION);

    const { data, loading, refetch } = useQuery(GET_DOCUMENTS_FLOTILLARS_BY_ID, {
        variables: { _eq: row.id },
        skip: !isOpen,
    });

    const validateDocuments = async () => {
        try {
            const response = await updateDocVerification({
                variables: {
                    doc_verification: true,
                    _eq: row.id,
                },
            });

            if (response?.data?.update_Flotilleros?.affected_rows > 0) {
                console.log('Documentos validados exitosamente');
                setButtonText('Documentos validados'); // Cambiar el texto del botón
                setButtonDisabled(true); // Deshabilitar el botón
            }
        } catch (error) {
            console.error('Error al validar los documentos:', error);
        }
    };

    const [updateValidationDocs] = useMutation(UPDATE_VALIDATION_DOCS_FLOTILLARS);

    useEffect(() => {
        if (onClose) {
            setDocumentsToShow([]);
            setApprovalState([]);
            refetch();
        }
    }, [isOpen, refetch, onClose]);

    useEffect(() => {
        if (data) {
            const docs = data?.Flotilleros[0]?.docs || [];
            const formattedDocs = docs.map(doc => ({
                documentId: doc.photo_docs_id,
                name: doc.doc_data.name,
                validation: doc.validation,
                src: doc.src,
            }));

            setDocumentsToShow(formattedDocs);
            setApprovalState(formattedDocs.map(doc => doc.validation));

            // Si los documentos ya están verificados, cambiar el texto y deshabilitar el botón
            if (row.doc_verification) {
                setButtonText('Documentos validados');
                setButtonDisabled(true);
            } else {
                setButtonText('Validar documentos');
                setButtonDisabled(false);
            }
        }
    }, [data, row.doc_verification]);

    const toggleApproval = async (index) => {
        const document = documentsToShow[index];
        if (!document || !document.documentId) {
            console.error('El documento o documentId no existe.');
            return;
        }

        const documentId = document.documentId;
        let newState = document.validation;

        if (newState === 'check') {
            newState = 'false';
        } else {
            newState = 'check';
        }

        const updatedState = [...approvalState];
        updatedState[index] = newState;
        setApprovalState(updatedState);

        const updatedDocuments = [...documentsToShow];
        updatedDocuments[index].validation = newState;
        setDocumentsToShow(updatedDocuments);

        try {
            await updateValidationDocs({
                variables: {
                    validation: newState,
                    _eq: documentId,
                },
            });
        } catch (error) {
            console.error("Error al actualizar el estado del documento:", error);
        }
    };

    const openDocumentLink = (src) => {
        window.open(src, '_blank');
    };

    const isAllDocumentsChecked = documentsToShow.every(doc => doc.validation === 'check');
    const isButtonDisabled = buttonDisabled || !isAllDocumentsChecked; // Deshabilitar si ya está validado o no están todos en 'check'

    return (
        <Modal
            className={`bg-gray-100 flex flex-col rounded-xl border-4 w-modal h-modal border-orange-500 p-4`}
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Detalles del Usuario"
            style={{
                overlay: {
                    backgroundColor: '#00000099',
                    overflow: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingLeft: '17vw',
                },
                content: {
                    overflowY: "auto",
                },
            }}
        >
            <div className="flex justify-end">
                <button className="p-1" onClick={onClose}>
                    <HighlightOffIcon className="text-orange-500 hover:bg-orange-600 hover:text-white rounded-full text-4xl mr-1" style={{ fontSize: '2rem' }} />
                </button>
            </div>
            <strong className='text-center text-xl'>Flotillero</strong>
            <strong className='text-center text-2xl'>{row.Name} {row.Last_name}</strong>
            <div className='grid grid-cols-2 my-6'>
                <div className="flex items-center justify-center">
                    {row.photo_flotillero ? (
                        <img src={row.photo_flotillero} alt={`${row.Name} ${row.Last_name}`} style={{
                            borderRadius: '50%',
                            width: '300px',
                            height: '300px',
                            objectFit: 'cover',
                        }} />
                    ) : (
                        <div>No hay imagen disponible</div>
                    )}
                </div>
                <div className="space-y-10">
                    <p><strong>ID:</strong> {row.id}</p>
                    <p><strong>Correo:</strong> {row.Email}</p>
                    <p><strong>Celular:</strong> {row.Phone_number}</p>
                    <p><strong>Registro:</strong> {convertTimestampToDate(row.date_registration_flotilla)}</p>
                    <p><strong>Documentos Verificados:</strong> {row.doc_verification ? 'Sí' : 'No'}</p>
                </div>
            </div>
            <div>
                <h2 className='text-center text-xl font-bold rounded-2xl text-white mb-2 bg-orange-400'>
                    Documentos
                </h2>
                {loading ? (
                    <p>Cargando documentos...</p>
                ) : documentsToShow.length === 0 ? (
                    <p className='text-center'>No hay documentos del conductor</p>
                ) : (
                    <div>
                        {documentsToShow.map((document, index) => (
                            <div className='grid grid-cols-8 text-xl mb-1' key={index}>
                                <div className='col-span-6 text-lg row-auto bg-orange-500 rounded-l-2xl text-center text-white px-2'>
                                    <h1 className='mt-1 mb-1 truncate ...'>
                                        {document?.name || 'N/A'}
                                    </h1>
                                </div>
                                <div className={`col-span-1 ${approvalState[index] === 'check' ? 'bg-green-600' : approvalState[index] === 'false' ? 'bg-red-600' : 'bg-yellow-500'}`}>
                                    <button
                                        className='w-full h-full flex items-center justify-center'
                                        onClick={() => toggleApproval(index)}
                                    >
                                        {approvalState[index] === 'check' ? (
                                            <Tooltip title="Cambiar a 'invalido'" placement='top' arrow>
                                                <CheckIcon className='text-white' style={{ fontSize: '2rem' }} />
                                            </Tooltip>
                                        ) : approvalState[index] === 'false' ? (
                                            <Tooltip title="Cambiar a 'aprobado'" placement='top' arrow>
                                                <CloseIcon className='text-white bg-red-600' style={{ fontSize: '2rem' }} />
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title="En espera de validación" placement='top' arrow>
                                                <TimerIcon className='text-white' style={{ fontSize: '2rem' }} />
                                            </Tooltip>
                                        )}
                                    </button>
                                </div>
                                <div className='col-span-1 bg-orange-500 rounded-r-2xl flex items-center ml-1 justify-center px-4'>
                                    <button className='text-gray-50' onClick={() => openDocumentLink(document.src)}>
                                        <Tooltip title="Ver documento" placement='top' arrow>
                                            <VisibilityIcon className='text-gray-50' style={{ fontSize: '2rem' }} />
                                        </Tooltip>
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className="flex justify-center mt-4">
                <button
                    className={`p-2 rounded-lg text-white text-xl ${buttonDisabled ? 'bg-green-500 cursor-not-allowed' : isButtonDisabled ? 'bg-gray-500 cursor-not-allowed' : 'bg-green-500'
                        }`}
                    onClick={validateDocuments}
                    disabled={isButtonDisabled}
                >
                    {buttonText}
                </button>
            </div>
        </Modal>
    );
};

export default ModalFlotillero;
