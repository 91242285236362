export const getFirstDayOfMonth = () => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const getCurrentDay = () => {
  return new Date();
};

export const convertDateToTimestamp = (date) => {
  return Math.floor(new Date(date).getTime());
};

export const convertTimestampToDate = (timestamp) => {
  const date = new Date(timestamp);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const groupUsersByDay = (users) => {
  const userCountByDay = {};

  users.forEach((user) => {
    const date = convertTimestampToDate(user.register_date);
    if (userCountByDay[date]) {
      userCountByDay[date] += 1;
    } else {
      userCountByDay[date] = 1;
    }
  });

  return userCountByDay;
};
