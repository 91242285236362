import * as echarts from 'echarts';
import React, { useEffect, useRef } from 'react';

const Historial = ({ dataHistory, loadingHistory }) => {
    const chartRef = useRef(null);

    const zonasMap = {};
    if (dataHistory && dataHistory.trips) {
        dataHistory.trips.forEach(viaje => {
            const zonaNombre = viaje.zona_data?.nombre || 'Desconocido';
            const status = viaje.status || 'Desconocido';

            if (!zonasMap[zonaNombre]) {
                zonasMap[zonaNombre] = { Terminado: 0, Cancelado: 0 };
            }
            if (status === 'Terminado') {
                zonasMap[zonaNombre].Terminado += 1;
            } else if (status === 'Cancelado') {
                zonasMap[zonaNombre].Cancelado += 1;
            }
        });
    }

    const zonasOrdenadas = Object.entries(zonasMap).sort(
        ([, a], [, b]) => (b.Terminado + b.Cancelado) - (a.Terminado + a.Cancelado)
    );

    let barColorTerminado = '#4CAF50'; // Verde
    let barColorCancelado = '#F44336'; // Rojo

    const zonaNombres = zonasOrdenadas.map(([zona]) => zona);
    const zonaTerminado = zonasOrdenadas.map(([, data]) => data.Terminado);
    const zonaCancelado = zonasOrdenadas.map(([, data]) => data.Cancelado);

    useEffect(() => {
        if (chartRef.current) {
            const chartInstance = echarts.init(chartRef.current);
            const options = {
                tooltip: {
                    trigger: 'axis',
                },
                legend: {
                    data: ['Terminados', 'Cancelados'],
                },
                xAxis: {
                    type: 'category',
                    data: zonaNombres,
                    axisLabel: {
                        rotate: 90,
                        interval: 0,
                    },
                },
                yAxis: {
                    type: 'value',
                },
                series: [
                    {
                        name: 'Terminados',
                        type: 'bar',
                        data: zonaTerminado,
                        itemStyle: {
                            color: barColorTerminado,
                        },
                    },
                    {
                        name: 'Cancelados',
                        type: 'bar',
                        data: zonaCancelado,
                        itemStyle: {
                            color: barColorCancelado,
                        },
                    },
                ],
            };

            chartInstance.setOption(options);

            return () => {
                chartInstance.dispose();
            };
        }
    }, [zonaNombres, zonaTerminado, zonaCancelado]);

    return (
        <div className="flex items-center justify-center h-full">
            {zonaNombres.length > 0 ? (
                <div>
                    <h2 className="text-xl font-bold text-center">Viajes por zona</h2>
                    <div
                        ref={chartRef}
                        style={{ width: '1000px', height: '350px' }}
                    ></div>
                </div>
            ) : (
                <h2 className="text-xl font-bold text-center">
                    No hay viajes en el rango de fechas seleccionado
                </h2>
            )}
        </div>
    );
}

export default React.memo(Historial);
