import { gql } from "@apollo/client";

const ExcludeDrivers = `[
  "GaNbEclsmNSQ7dATmcoJXBSp8Xv2"
  "oLPjQdQLIPba046rYrbnbD1Dhvc2"
  "WKQ4T5IaRoX37a3RNtQXjzJJRwT2"
  "KoIFKGeGykWIhAlJ8qJQz05eAR53"
  "A0U99TjgnIdE1KRKyHeOGUPMgaW2"
  "WQfQGwr1W5SHA4MsJ0wvUEqDdM23"
  "nnwuMZZfL0NxqcqHNYrP6mQyqaU2"
  "yWI9ZsZIpdROvwoeCfCELwa6eWC3"
  "b9Z1DZ1XxDWYyNSpGWlYqnV8MrC3"
  "a8BuCU0MkldJjdREKFRuP7zD6Ne2"
  "fELeNzjUaNNxzVzkAkHm52ReK493"
  "5kxvIh5XODensaL7u4xcxXGJKez2"
  "R8sDDRxyFdhWZkb9Ac9R8f1kCQZ2"
  "NyPuFsoG1uZvkN7kVMsHG1rTZPu2"
  "wHgUdjF7otMlyjgyybYqRjK9oII3"
]`;

export const ALL_USERS = gql`
  query AllUsers {
    user {
      email
      identification
      last_log_date
      last_name
      last_trip_date
      name
      pass
      phone
      photo
      rating
      register_date
      register_type
      status
      trip_count
      user_id
      user_trip_count {
        count
        rating
        user_id
      }
    }
  }
`;

export const TRIPS_BY_ID_USER = gql`
  query TripsByIdUser($_eq: String!) {
    trips(order_by: { time_request: asc }, where: { user_id: { _eq: $_eq } }) {
      # distance
      driver_id
      # drivers_canceled {
      #   id
      #   driver_id
      #   callback
      #   trip_id
      # }
      # drivers_notified {
      #   id
      #   driver_id
      #   trip_id
      # }
      environment
      # estimated_time
      # impuestos_isr
      # locations {
      #   description
      #   latitude
      #   longitude
      # }
      pago_total
      # panic_button
      # payment_method
      # rate_id
      # reasons_cancellation
      status
      # tarifa_total
      # tarifa_total_descuento
      # time_accepted
      # time_canceled
      # time_completed
      # time_course
      # time_origin
      # time_request
      # time_to_origin
      trip_id
      user_id
      vehicle_id
      # viaje_minutos
      # who_cancel
    }
  }
`;

export const ALL_TRIPS = gql`
  query AllTrips {
    trips(order_by: { time_request: asc }) {
      distance
      driver_id
      drivers_canceled {
        id
        driver_id
        callback
        trip_id
      }
      drivers_notified {
        id
        driver_id
        trip_id
      }
      environment
      estimated_time
      impuestos_isr
      locations {
        description
        latitude
        longitude
      }
      pago_total
      panic_button
      payment_method
      rate_id
      reasons_cancellation
      status
      tarifa_total
      tarifa_total_descuento
      time_accepted
      time_canceled
      time_completed
      time_course
      time_origin
      time_request
      time_to_origin
      trip_id
      type
      user_id
      vehicle_id
      viaje_minutos
      who_cancel
    }
  }
`;

export const DRIVERS_NOTIFIED = gql`
  query DriversNotified {
    trips_drivers_notified {
      trip_id
      id
      driver_id
      callback
    }
  }
`;

export const DRIVERS_ON_LINE = gql`
  query DriversOnLine @cached {
    drivers(
      where: {
        environment: { _eq: "2" }
        is_connected: { timestamp: { _is_null: false } }
      }
    ) {
      driver_id
      address_state
      address_locality
      last_name
      name
      status
      vehicle_id_type {
        name
      }
      inline: is_connected {
        timestamp
      }
    }
  }
`;

export const STATUS_DRIVER = gql`
  query MyQuery($startDate: bigint!, $endDate: bigint!) {
    drivers(
      where: {
        driver_id: {
          _nin: ${ExcludeDrivers}
        }
        environment: { _eq: "2" }
        date_registration: { _gte: $startDate, _lt: $endDate }
      }
    ) {
      status
      date_registration
    }
  }
`;

export const STATUS_DRIVER_ZONE = gql`
  query MyQuery($startDate: bigint!, $endDate: bigint!, $address_locality: String!) {
    drivers(
      where: {
        driver_id: {
          _nin: ${ExcludeDrivers}
        }
        environment: { _eq: "2" }
        address_locality: { _eq: $address_locality } 
        date_registration: { _gte: $startDate, _lt: $endDate }
      }
    ) {
      status
      date_registration
    }
  }
`;

export const GET_POLIGONOS = gql`
  query MyQuery {
    poligonos(where: { environment: { _eq: "2" } }) {
      status
      poligono_id
      poligono
      nombre
      fecha_creacion
      environment
    }
  }
`;

export const COMPLETED_TRIPS = gql`
  query MyQuery($endDate: bigint!, $startDate: bigint!) {
    trips(
      where: {
        driver_id: {
          _nin: ${ExcludeDrivers}
        }
        status: { _eq: "Terminado" }
        environment: { _eq: "2" }
        time_accepted: { _gte: $startDate, _lt: $endDate }
      }
    ) {
      time_accepted
      driver_data {
        vehicle_id_type {
          name
        }
      }
    }
  }
`;

export const GET_FLOTILLARS = gql`
  query MyQuery($limit: Int!, $startDate: bigint!, $endDate: bigint!) {
    Flotilleros(
      order_by: { date_registration_flotilla: desc }
      limit: $limit
      where: { date_registration_flotilla: { _gte: $startDate, _lt: $endDate } }
    ) {
      id
      photo_flotillero
      Email
      Last_name
      Name
      Phone_number
      date_registration_flotilla
      doc_verification
      docs {
        doc_data {
          name
          document_id
          Registration
          index
        }
        validation
        document_id
      }
      flotillas {
        nombre
      }
      vehiculos {
        vehicle_plates
      }
    }
  }
`;

export const GET_DOCUMENTS_FLOTILLARS_BY_ID = gql`
  query MyQuery($_eq: String!) {
    Flotilleros(where: { id: { _eq: $_eq } }) {
      docs(order_by: { document_id: desc }) {
        doc_data {
          name
          document_id
          Registration
          index
        }
        validation
        photo_docs_id
        src
      }
    }
  }
`;

export const GET_TRAVEL_HISTORY = gql`
  query MyQuery($startDate: bigint!, $endDate: bigint!) {
    trips(where: { time_request: { _gte: $startDate, _lte: $endDate } }) {
      trip_id
      status
      zona_data {
        nombre
      }
    }
  }
`;

export const GET_USERS = gql`
  query MyQuery($startDate: String!, $endDate: String!) {
    user(
      order_by: { register_date: desc }
      where: { register_date: { _gte: $startDate, _lte: $endDate } }
    ) {
      user_id
      register_date
    }
  }
`;

export const GET_TYPE_VEHICLE = gql`
  query MyQuery($startDate: bigint!, $endDate: bigint!) {
    drivers(
      order_by: {date_registration: desc}
      where: {
        driver_id: {
          _nin: ${ExcludeDrivers}
        }
        environment: { _eq: "2" }
        date_registration: { _gte: $startDate, _lt: $endDate }
        vehicle_id_type: {_not: {name: {_eq: "null"}}}
        }
    ) {
      address_locality
      vehicle_id_type {
        name
      }
    }
  }
`;
