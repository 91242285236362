import React, { useCallback, useMemo } from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

function SelectZones({ poligons, setCity, city, setNameCity }) {
    const opt = useMemo(() => {
        const places = poligons?.poligonos ?? [];
        return [
            { label: "Todas las zonas", value: "Todas las zonas" },
            ...places
                .filter((value) => value.nombre !== "prueba poligono" &&
                    value.nombre !== "Guadalajara Prod")
                .map((value) => {
                    let label = value.nombre;
                    if (label === "Puebla") {
                        label = "Heroica Puebla de Zaragoza"; 
                    }
                    return {
                        label,
                        value: value.poligono_id,
                    };
                }),
        ];
    }, [poligons]);

    const handleSelectChange = useCallback(
        (event) => {
            const value = event.target.value;
            setCity(value);

            const selectedPlace = opt.find((option) => option.value === value);
            if (selectedPlace) {
                if (typeof setNameCity === "function") {
                    setNameCity(selectedPlace.label);
                } else {
                    console.error("setNameCity no es una función");
                }
            }
        },
        [opt, setCity, setNameCity]
    );

    return (
        <FormControl variant="outlined" fullWidth style={{ marginLeft: '20px', width: '250px' }}>
            <InputLabel id="select-zones-label">Selecciona la zona</InputLabel>
            <Select
                labelId="select-zones-label"
                value={city}
                onChange={handleSelectChange}
                label="Selecciona la zona"
            >
                {opt.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default SelectZones;
