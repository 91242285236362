import React, { useState } from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const DropdownFechas = ({ setStartDate, setEndDate }) => {
    const [selectedOption, setSelectedOption] = useState("Fechas preseleccionadas");

    const options = [
        { label: "Hoy", value: "hoy" },
        { label: "Ayer", value: "ayer" },
        { label: "Esta semana", value: "esta_semana" },
        { label: "Este mes", value: "este_mes" },
        { label: "Desde el origen", value: "desde_el_origen" },
    ];

    const handleChange = (event) => {
        const option = options.find(opt => opt.value === event.target.value);
        setSelectedOption(option.value);
        const today = new Date();
        let startDate, endDate;

        switch (option.value) {
            case "hoy":
                startDate = today;
                endDate = today;
                break;
            case "ayer":
                today.setDate(today.getDate() - 1);
                startDate = today;
                endDate = today;
                break;
            case "esta_semana":
                const startOfWeek = new Date(today);
                startOfWeek.setDate(today.getDate() - today.getDay());
                endDate = today;
                startDate = startOfWeek;
                break;
            case "este_mes":
                startDate = new Date(today.getFullYear(), today.getMonth(), 1);
                endDate = today;
                break;
            case "desde_el_origen":
                startDate = new Date(2022, 0, 1);
                endDate = today;
                break;
            default:
                break;
        }

        setStartDate(startDate);
        setEndDate(endDate);
    };

    return (
        <FormControl variant="outlined" fullWidth style={{ marginLeft: '20px', width: '250px' }}>
            <InputLabel id="fechas-select-label">Fechas preseleccionadas</InputLabel>
            <Select
                labelId="fechas-select-label"
                value={selectedOption}
                onChange={handleChange}
                label="Fechas preseleccionadas"
            >
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default DropdownFechas;
